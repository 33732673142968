/**
 * Secondary Question Component
 * 
 * An individual secondary question item. Shows the question's text, input, hint, and other information
 * specific to each question. Also handles the answer submission and points allocation
 * for this question. Used in the Final Question Page.
 */

import React from 'react'
import PropTypes from 'prop-types'
import ReactCodeInput from 'react-code-input'
import {
  getCurrentTeamData,
  teamAnswerSecondaryQuestion,
  teamAddPoints,
  isTeamCaptain,
  getHuntStatus,
} from '../../utils/team-data'
import QuestionEnd from './end'
import Modal from 'react-bootstrap/Modal'
import FloatingInput from '../floating-input'
import { KeysIllustration } from '../images/illustrations'
import SvgCheck from '../svg/check'
import striptags from 'striptags'
import { navigate } from 'gatsby'
import { b64DecodeUnicode, getStaticUrl, getHighlightedIndexes } from '../../utils/helpers'
import { getCurrentHuntData } from '../../utils/hunt-data'

class SecondaryQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      numGuesses: 0, // number of guesses
      answer: ``, // the current guess/answer
      isCorrect: false,
      prevAnswers: [], // record all submitted answers
      isDirty: false, // marks if the input has changed but not been submitted/validated
      points: 0,
      modalShow: false,
      modalStyle: `danger`,
      buttonText: `Next Question`,
      questionState: ``,
      isGnG: false,
      spaces_answers: [],
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
    this.checkAnswer = this.checkAnswer.bind(this)
    this.updateFromData = this.updateFromData.bind(this)
    this.handleNextQuestion = this.handleNextQuestion.bind(this)
    this.handleSpacesUpdate = this.handleSpacesUpdate.bind(this)

    this.getAnswers = this.getAnswers.bind(this)
    this.getSpaces = this.getSpaces.bind(this)
  }

  componentDidMount() {
    this.updateFromData()
    this.interval = setInterval(() => this.updateFromData(), 15 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  updateFromData() {
    const { question } = this.props
    const curTeamData = getCurrentTeamData()
    if (
      curTeamData !== undefined &&
      curTeamData.secondary_answers !== undefined &&
      curTeamData.secondary_answers !== null &&
      curTeamData.secondary_answers[question.id] !== undefined
    ) {
      const prevAnswer = curTeamData.secondary_answers[question.id]
      this.setState(prevAnswer)
      let q_state = ``
      if (prevAnswer.isCorrect) {
        if (prevAnswer.points == question.answer_value) {
          q_state = `correct`
        } else {
          q_state = `partial`
        }
      } else {
        if (prevAnswer.numGuesses >= this.props.allowedGuesses) {
          q_state = `wrong`
        }
      }
      this.setState({ questionState: q_state })
    }
    let state = getHuntStatus()
    if (state == `ended`) {
      clearInterval(this.interval)
    }
    const data = getCurrentHuntData()
    if (data.hunt !== undefined) {
      let hunt_type = data.hunt.hunt_type !== undefined ? data.hunt.hunt_type : 0
      this.setState({ isGnG: hunt_type == 1 })
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    this.checkAnswer()
  }

  handleUpdate(event) {
    this.setState({
      isDirty: true,
      answer: event.target.value,
    })
  }

  handleNextQuestion() {
    this.setState({ modalShow: false })
    // go to the next question if correct, or exhausted guesses
    if (this.state.isCorrect || this.state.numGuesses >= this.props.allowedGuesses) {
      this.props.nextFunc()
    }
  }

  getAnswers() {
    const { question } = this.props
    const crypt_answers = question.answers_aes_crypt
    let answers = b64DecodeUnicode(crypt_answers)
    //console.log(answers)
    let possible_answers = []
    answers.split(`,`).map(answer => {
      // case insensitive and trim any whitespace
      possible_answers.push(answer.trim().toLowerCase())
    })
    return possible_answers
  }

  checkAnswer() {
    const { question, success, softFail, fail } = this.props
    const possible_answers = this.getAnswers() // this is an array of lowercase strings of possible answers
    //console.log(possible_answers)
    let guess = this.state.answer.trim().toLowerCase() // case insensitive and trim any whitespace
    // fix for iOS - convert forward and backtick single quote to normal vertical one
    guess = guess.replace(/[\u2018\u2019]/g, `'`)
    //console.log(guess)

    if (guess.length) {
      var correct = false
      var points = 0
      if (possible_answers.includes(guess)) {
        // Answered correctly!
        correct = true
        // play the success sound!
        success()
        if (this.state.numGuesses == 0) {
          points = question.answer_value ? question.answer_value : 5
          this.setState({
            modalStyle: `success`,
            buttonText: `Next Question`,
            questionState: `correct`,
          })
        } else {
          points = question.hint_value ? question.hint_value : 3
          this.setState({
            modalStyle: `warning`,
            buttonText: `Next Question`,
            questionState: `partial`,
          })
        }
        // teamAddPoints(points)
      } else {
        // play the fail audio
        if (this.state.numGuesses + 1 < this.props.allowedGuesses) {
          softFail()
          this.setState({
            modalStyle: ``,
            buttonText: `Try Again`,
          })
        } else {
          fail()
          this.setState({
            modalStyle: `danger`,
            buttonText: `Next Question`,
            questionState: `wrong`,
          })
        }
      }
      let answerData = {
        question_id: question.id,
        numGuesses: this.state.numGuesses + 1,
        isCorrect: correct,
        prevAnswers: [...this.state.prevAnswers, guess],
        points: points,
      }
      // record the answer to the localstorage
      teamAnswerSecondaryQuestion(answerData)

      // update the state to set the correctness, guesses, etc
      this.setState({
        answer: ``,
        isDirty: false,
        numGuesses: this.state.numGuesses + 1,
        isCorrect: correct,
        prevAnswers: [...this.state.prevAnswers, guess],
        points: points,
        modalShow: true,
      })
    }
  }

  getSpaces() {
    const { question } = this.props
    if( question.spaces ){
      return question.spaces
    } else {
      const answer = this.getAnswers()[0].trim()
      var spaces = answer.replace(/[^\s]/g, `_`)
      spaces = spaces.replace(/\s+/g, `+`)
      return spaces
    }
  }

  handleSpacesUpdate(value, index) {
    var { spaces_answers } = this.state
    spaces_answers[index] = value
    let answer = spaces_answers.join(` `)
    this.setState({
      spaces_answers: spaces_answers,
      answer: answer,
    })
  }

  // Add modal to question, change color depending on number of tries.

  render() {
    const { question, direction, hintEnabled, allowedGuesses, showAnswersEnabled, q_num, total_q } = this.props
    const isCorrect = this.state.isCorrect
    const isIncorrect = !this.state.isDirty && this.state.numGuesses > 0 && !isCorrect
    const potentialPoints = isIncorrect ? question.hint_value : question.answer_value
    const showHint = this.state.numGuesses >= 1
    const inputHelpText = question.input_help_text ? question.input_help_text : ``
    const answer = this.getAnswers()[0]
    const isCaptain = isTeamCaptain()
    const huntStatus = getHuntStatus()
    const showInput = isCaptain && huntStatus !== `ended`
    const prevAnswer = this.state.prevAnswers ? this.state.prevAnswers[this.state.prevAnswers.length - 1] : false
    let directions = ``
    let modalClose = () => this.setState({ modalShow: false })

    const answer_spaces = this.getSpaces()
    const words = answer_spaces.split(`+`)

    switch (direction) {
    case `clockwise`:
      directions = question.directions_clockwise
      break
    case `counterclockwise`:
      directions = question.directions_counterclockwise
      break
    case `start`:
      directions = question.directions_from_start
      break
    }
    const showEnd = isCorrect || this.state.numGuesses >= allowedGuesses

    let buttonStyle = `btn-danger`
    if (this.state.questionState == `wrong` || isIncorrect) {
      buttonStyle = `btn-success`
    }
    if (showEnd) {
      buttonStyle += ` btn-arrow`
    }

    let modalFooterClass = ``
    if (!showEnd) {
      modalFooterClass = `no-background`
    }

    // Set button text on final question
    let button_text = this.state.buttonText
    if (this.props.q_num == this.props.total_q) {
      button_text = `Continue`
    } else {
      button_text = this.state.buttonText
    }

    const question_position = (
      <div className={`col-5`}>
        <p className={`my-0 text-center header-data`}>
          <small className={`d-block mb-1`}>Question</small>
          <span>
            {q_num}/{total_q}
          </span>
        </p>
      </div>
    )

    return (
      <div className={`question-slide`}>
        <div className={`mx-4 mb-5 question-card card full-height ${this.state.questionState}`}>
          <div className={`card-header question-header`}>
            <div className={`row align-items-center justify-content-between`}>
              <div className={`col-9`}>
                <div className="d-flex align-items-center">
                  <span className={`my-0 header-data`}>
                    {q_num}/{total_q}
                  </span>
                  <span className="pl-3 directions">{question.directions_simple}</span>
                </div>
              </div>
              {/* <div className={'col-6 text-center'}>
                <span>{question.directions_simple}</span>
              </div> */}
              {showEnd ? (
                <div className={`col-3 text-right text-lowercase`}>
                  <p className={`my-0 points`}>
                    <span className={`mr-2`}>
                      {this.state.points}/{question.answer_value} pts
                    </span>
                    <span
                      className={`check-circle smaller ${this.state.questionState}`}
                      style={{
                        position: `relative`,
                        top: `-3px`,
                      }}>
                      <span className={`d-flex align-items-center justify-content-center w-100 h-100`}>
                        <SvgCheck />
                      </span>
                    </span>
                  </p>
                </div>
              ) : (
                <div className={`col-3 text-right`}>
                  {huntStatus !== `ended` && (
                    <p className={`my-0 points text-lowercase`}>
                      <span>
                        {potentialPoints}/{question.answer_value} pts
                      </span>
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={`card-body d-flex flex-column`}>
            <div className={`directions`} dangerouslySetInnerHTML={{ __html: directions }} />

            <div className={`question mb-0 mt-3`} dangerouslySetInnerHTML={{ __html: question.question }} />
            {prevAnswer && !isCorrect && <p className="mb-1"><strong>Submitted Answer:</strong> <span className="text-uppercase" style={{letterSpacing: `1px`}}>{prevAnswer}</span></p>}
            {hintEnabled && showHint && question.hint != `` && (
              <small className="hint mb-3">
                <strong className="hint-title subtitle h3 text-uppercase">Hint:</strong>
                {` `}
                <span dangerouslySetInnerHTML={{ __html: question.hint }} />
              </small>
            )}

            {showEnd ? (
              <>
                {showAnswersEnabled ? (
                  <p className="pb-3 title h5 text-uppercase text-center mt-auto">
                    <strong>Answer:</strong> {answer}
                  </p>
                ) : (
                  <p className="pb-3 title h5 text-uppercase text-center mt-auto">
                    <strong className={`d-block`}>Answer already submitted.</strong>
                  </p>
                )}
              </>
            ) : (
              <>
                {showInput ? (
                  <>
                    {this.state.numGuesses < allowedGuesses && (
                      <form
                        className={`question-form mt-auto pb-3`}
                        method="post"
                        onSubmit={event => this.handleSubmit(event)}>
                        {question.show_spaces ? 
                          <>
                            {question.input_help_text && <span className="d-block mb-3 text-success font-italic">{question.input_help_text}</span>}
                            {words.map((word, idx) => {
                              const num_spaces = word.match(/_/g).length
                              const highlighted_indexes = getHighlightedIndexes(word)
                              return(
                                <span className="answer-input d-inline-block mr-3 mb-3" key={question.id+`-`+idx}>
                                  <ReactCodeInput 
                                    label={inputHelpText}
                                    type="text"
                                    fields={num_spaces}
                                    autoFocus={false}
                                    forceUppercase={true}
                                    highlightCharIndex={highlighted_indexes}
                                    onChange={(value) => this.handleSpacesUpdate(value, idx)}
                                  />
                                </span>
                              )
                            }
                            )}
                          </>
                          :
                          <FloatingInput
                            name={`answer-${question.id}`}
                            placeholder={inputHelpText}
                            onChange={this.handleUpdate}
                            value={this.state.answer}
                          />
                        }
                        <input className={`btn btn-block btn-danger`} type="submit" value="Submit Answer!" />
                      </form>
                    )}
                  </>
                ) : (
                  <>
                    {question.input_help_text && <span className="text-success font-italic">{question.input_help_text}</span>}
                    {question.show_spaces &&
                      <>
                        {words.map((word, idx) => {
                          const num_spaces = word.match(/_/g).length
                          const highlighted_indexes = getHighlightedIndexes(word)
                          return(
                            <span className="answer-input d-inline-block mr-3 mb-3" key={question.id+`-`+idx}>
                              <ReactCodeInput 
                                label={inputHelpText}
                                type="text"
                                disabled="true"
                                fields={num_spaces}
                                autoFocus={false}
                                forceUppercase={true}
                                highlightCharIndex={highlighted_indexes}
                              />
                            </span>
                          )
                        }
                        )}
                      </>
                    }
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Modal
          show={this.state.modalShow}
          onHide={modalClose}
          centered
          dialogClassName={`defaultDialog`}
          backdropClassName={`app-overlay`}>
          <Modal.Body>
            <div className={`card question-modal-card ${this.state.modalStyle}`}>
              <div className={`card-header`}>
                <div className={`row align-items-center justify-content-between`}>
                  {question_position}
                  {showEnd ? (
                    <div className={`col-4 text-right`}>
                      <span className={`check-circle`}>
                        <span className={`d-flex align-items-center justify-content-center w-100 h-100`}>
                          <SvgCheck />
                        </span>
                      </span>
                    </div>
                  ) : (
                    <div className={`col-4 text-center`}>
                      <p className={`my-0 header-data`}>
                        <small className={`d-block`}>Worth</small>
                        <span>
                          {potentialPoints}/{question.answer_value} pts
                        </span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className={`card-body px-0 py-0`}>
                {showEnd ? (
                  <QuestionEnd
                    showAnswer={showAnswersEnabled}
                    answer={answer}
                    points={this.state.points}
                    possiblePoints={question.answer_value}
                    successText={this.props.successText}
                    partialSuccessText={this.props.partialSuccessText}
                    failText={this.props.failText}
                  />
                ) : (
                  <div className="incorrect-answer py-5 px-5 text-center">
                    <KeysIllustration />
                    <p className="mt-3">Sorry, that's not the correct answer!</p>
                  </div>
                )}
              </div>
              <div className={`card-footer py-3 ${modalFooterClass}`}>
                <button className={`btn ${buttonStyle} btn-block`} onClick={this.handleNextQuestion}>
                  {button_text}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

SecondaryQuestion.propTypes = {
  q_num: PropTypes.number,
  total_q: PropTypes.number,
  question: PropTypes.object,
  direction: PropTypes.string,
  hintEnabled: PropTypes.bool,
  showAnswersEnabled: PropTypes.bool,
  allowedGuesses: PropTypes.number,
  success: PropTypes.func,
  softFail: PropTypes.func,
  fail: PropTypes.func,
  nextFunc: PropTypes.func,
}

SecondaryQuestion.defaultProps = {
  q_num: 1,
  total_q: 1,
  question: {},
  direction: `clockwise`,
  hintEnabled: true,
  showAnswersEnabled: true,
  allowedGuesses: 2,
  success: () => {},
  softFail: () => {},
  fail: () => {},
  nextFunc: () => {},
}

export default SecondaryQuestion
