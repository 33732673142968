/**
 * Final/Secondary Questions Page
 * 
 * Shows a slider where each slide is a secondary question. Used in games
 * like the murder mystery hunts, where everyone has the same 3 final questions,
 * regardless of their team starting question or direction.
 */
import React from 'react'
import { navigate } from 'gatsby'
import Slider from 'react-slick'

import SecondaryQuestion from '../../components/question/secondary-question'
import FinishBlock from '../../components/card-finish'
import CardThanksForPlaying from '../../components/card-thanks'
import CardScore from '../../components/card-score'

import { getSiteData } from '../../utils/site'
import { getHuntStatus, getTeamSecondaryQuestionsMeta, getTeamSecondaryQuestionState, getCurrentTeamData } from '../../utils/team-data'
import LoggedInWrapper from '../../components/loggedinWrapper'
import SvgArrow from '../../components/svg/arrow'
import Link from '../../components/link'
import CompleteModal from '../../components/complete-modal'
import AppContext from '../../utils/context'
import { getCurrentHuntData } from '../../utils/hunt-data'
import { getStaticUrl } from '../../utils/helpers'

export default class SecondaryQuestionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      globalSettings: {},
      hunt_status: `pending`,
      play_sounds: true,
      modalShow: false,
      modalDismissed: false,
      end_text: {
        success: ``,
        parital: ``,
        fail: ``,
      },
      huntEnded: false,
      showPoints: false,
      currentQuestion: 1,
      questionState: ``,
      slider: null,
      dotSlider: null,
      isGnG: false,
    }
    this.firstClientX = 0
    this.sliderContainer = React.createRef()
    this.initializeSlider = this.initializeSlider.bind(this)
    this.initialize = this.initialize.bind(this)
    this.modalClose = this.modalClose.bind(this)
    this.nextSlide = this.nextSlide.bind(this)
    this.prevSlide = this.prevSlide.bind(this)
    this.afterFinish = this.afterFinish.bind(this)
    this.changeSlide = this.changeSlide.bind(this)
    this.beforeChangeSlide = this.beforeChangeSlide.bind(this)
    this.preventTouch = this.preventTouch.bind(this)
    this.touchStart = this.touchStart.bind(this)

    this.playSuccess = this.playSuccess.bind(this)
    this.playSoftFail = this.playSoftFail.bind(this)
    this.playFail = this.playFail.bind(this)
    // https://stackoverflow.com/questions/47686345/playing-sound-in-reactjs
  }

  componentDidMount() {
    this.setLoading(true)
    let data = getCurrentHuntData()
    let team_data = getCurrentTeamData()
    let globalSettings = getSiteData()
    let hunt_status = getHuntStatus()
    if (hunt_status != `started`) {
      navigate(`/`)
    }
    this.setState(
      {
        data: data,
        teamData: team_data,
        globalSettings: globalSettings,
        hunt_status: hunt_status,
      },
      this.initialize
    )

    this.interval = setInterval(() => this.checkComplete(), 5 * 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    this.sliderContainer.current.removeEventListener(`touchstart`, this.touchStart)
    this.sliderContainer.current.removeEventListener(`touchmove`, this.preventTouch, {
      passive: false,
    })
  }

  initialize() {
    const { data } = this.state
    this.initializeSlider()
    if (data.hunt !== undefined) {
      let hunt_type = data.hunt.hunt_type !== undefined ? data.hunt.hunt_type : 0
      this.setState({ isGnG: hunt_type == 1 })
    }
    const end_text = this.getEndText(data)
    this.success_sound = data.hunt.sound_success ? data.hunt.sound_success.file : getStaticUrl(`/sounds/ding.mp3`)
    this.soft_fail_sound = data.hunt.sound_soft_fail ? data.hunt.sound_soft_fail.file : getStaticUrl(`/sounds/duck.mp3`)
    this.fail_sound = data.hunt.sound_fail ? data.hunt.sound_fail.file : getStaticUrl(`/sounds/sad_trombone.mp3`)
    let play_sounds = data.hunt.play_sounds == 1
    this.success_audio = new Audio(this.success_sound)
    this.fail_audio = new Audio(this.fail_sound)
    this.soft_fail_audio = new Audio(this.soft_fail_sound)
    this.setState({
      play_sounds: play_sounds,
      end_text: end_text,
    })
    this.setState(getTeamSecondaryQuestionsMeta())
    this.sliderContainer.current.addEventListener(`touchstart`, this.touchStart)
    this.sliderContainer.current.addEventListener(`touchmove`, this.preventTouch, { passive: false })
  }

  playSuccess() {
    if (this.state.play_sounds) {
      this.success_audio.play()
    }
  }

  playSoftFail() {
    if (this.state.play_sounds) {
      this.soft_fail_audio.play()
    }
  }

  playFail() {
    if (this.state.play_sounds) {
      this.fail_audio.play()
    }
  }

  initializeSlider() {
    const { data, teamData } = this.state
    if (this.props.location.hash) {
      let currentQuestion = parseInt(this.props.location.hash.substring(1))
      //this.setState({ currentQuestion: currentQuestion, questionState: getTeamQuestionState(currentQuestion - 1) })
      let question_index = currentQuestion - 1
      //console.log(question_index)
      this.slider.slickGoTo(question_index, true)
    } else {
      // we aren't going to a specific one, so go to the slide after the latest answered question
      let new_index = 0
      if (teamData.secondary_answers !== null && teamData.secondary_answers !== undefined) {
        data.secondary_questions.map((question, index) => {
          if (teamData.secondary_answers[question.id] !== undefined) {
            // check if it's complete
            const answer = teamData.secondary_answers[question.id]
            if (answer.isCorrect || answer.numGuesses >= 2) {
              // it's either correct, or wrong
              new_index = index + 1
            }
          }
        })
      }
      //this.setState({ currentQuestion: new_index + 1, questionState: getTeamQuestionState(new_index) })
      this.slider.slickGoTo(new_index, true)
    }
    this.setState(
      {
        slider: this.slider,
        dotSlider: this.dotSlider,
      },
      () => {
        this.setLoading(false)
      }
    )
  }

  modalClose() {
    clearInterval(this.interval)
    this.setState({
      modalShow: false,
      modalDismissed: true,
    })
  }

  finishGame() {
    navigate(`/finish/`)
  }

  checkComplete() {
    const { data } = this.state
    const teamData = getCurrentTeamData()
    this.setState({ teamData: teamData })
    let hunt_status = getHuntStatus()

    let q_meta = getTeamSecondaryQuestionsMeta()
    this.setState(q_meta)
    this.setState({ questionState: getTeamSecondaryQuestionState(this.state.currentQuestion - 1) })

    if (data.secondary_questions !== undefined) {
      let num_questions = data.secondary_questions.length

      if (q_meta.num_complete == num_questions && hunt_status !== `ended`) {
        // make sure another modal isn't open already
        if (!document.querySelector(`body.modal-open`)) {
          this.setState({ modalShow: true })
        }
      }
    }
  }

  nextSlide() {
    this.slider.slickNext()
    window.scrollTo(0, 0) // scroll back to the top when going to the next slide programmatically
  }

  prevSlide() {
    this.slider.slickPrev()
    window.scrollTo(0, 0) // scroll back to the top when going to the previous slide
  }

  getEndText(data) {
    const globalSettings = this.state.globalSettings
    let text = {
      success: `Good job smarty pants!`,
      partial: `Pretty good!`,
      fail: `Better luck next time!`,
    }

    if (data.hunt !== undefined && data.hunt.settings !== null && data.hunt.settings.success_text !== null) {
      text.success = data.hunt.settings.success_text
    } else if (globalSettings !== undefined && globalSettings.success_text !== null) {
      text.success = globalSettings.success_text
    }

    if (data.hunt !== undefined && data.hunt.settings !== null && data.hunt.settings.partial_success_text !== null) {
      text.partial = data.hunt.settings.partial_success_text
    } else if (globalSettings !== undefined && globalSettings.success_text !== null) {
      text.partial = globalSettings.partial_success_text
    }

    if (data.hunt !== undefined && data.hunt.settings !== null && data.hunt.settings.fail_text !== null) {
      text.fail = data.hunt.settings.fail_text
    } else if (globalSettings !== undefined && globalSettings.fail_text !== null) {
      text.fail = globalSettings.fail_text
    }

    return text
  }

  afterFinish() {
    console.log(`finished`)
    //this.setState({ huntEnded: true })
    navigate(`/finish/`)
  }

  changeSlide(index) {
    if (index < this.state.num_questions) {
      this.setState({ currentQuestion: index + 1, questionState: getTeamSecondaryQuestionState(index) })
    }
  }

  beforeChangeSlide(oldIndex, newIndex) {
    if (newIndex < this.state.num_questions) {
      this.setState({ currentQuestion: newIndex + 1, questionState: getTeamSecondaryQuestionState(newIndex) })
    }
  }

  touchStart(e) {
    this.firstClientX = e.touches[0].clientX
  }

  preventTouch(e) {
    const minValue = 5 // threshold

    let diff = e.touches[0].clientX - this.firstClientX

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(diff) > minValue) {
      // e.preventDefault()
      e.returnValue = false
      return false
    }
  }

  render() {
    const { data, teamData, hunt_status, showPoints, score, possible_points } = this.state

    const numSlides =
      (data.secondary_questions !== undefined ? data.secondary_questions.length : 0) +
      (this.state.huntEnded || hunt_status == `ended` ? 2 : 1)

    var sliderSettings = {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      asNavFor: this.state.dotSlider,
      //afterChange: this.changeSlide,
      //beforeChange: this.beforeChangeSlide,
      adaptiveHeight: true,
      useTransform: true,
      touchThreshold: 3, // user must swipe 1/touchThreshold of the width of the slide to advance
    }

    /* eslint-disable-next-line */
    var dotSliderSettings = {
      arrows: false,
      dots: false,
      infinite: true,
      draggable: false,
      swipe: false,
      speed: 500,
      slidesToShow: numSlides < 7 ? numSlides : 7,
      slidesToScroll: 1,
      centerMode: true,
      swipeToSlide: false,
      touchMove: false,
      centerPadding: `0px`,
      asNavFor: this.state.slider,
    }

    return (
      <AppContext.Consumer>
        {({ setLoading }) => {
          this.setLoading = setLoading
          return (
            <LoggedInWrapper>
              <div className="site-sub-header px-4 py-2">
                <div className="row">
                  <div className="col-2 d-flex align-items-center">
                    <Link to={`/`} className={`back-arrow`}>
                      <SvgArrow />
                    </Link>
                  </div>
                  <div className="col-10 text-right">
                    <h1 className={`title h5 smaller text-uppercase mb-0 d-inline-block`}>{data.hunt && data.hunt.secondary_questions_title ? data.hunt.secondary_questions_title : `Final Questions`}</h1>
                    {showPoints ? (
                      <>
                        <span className="points title h5 smaller ml-2">
                          ({score}/{possible_points})
                        </span>
                      </>
                    ) : (
                      <>
                        {/* <span className="points title h5 smaller mr-2">
                    {currentQuestion}/{num_questions}
                  </span> */}
                        {/* <span
                    className={`check-circle smaller ${questionStatusClass}`}
                    style={{
                      position: 'relative',
                      top: '-3px',
                    }}>
                    <span className={'d-flex align-items-center justify-content-center w-100 h-100'}>
                      <SvgCheck />
                    </span>
                  </span> */}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className={`mt-3 mb-3 pb-md-5`}>
                {data.secondary_questions !== undefined && (
                  <div ref={this.sliderContainer}>
                    <Slider ref={slider => (this.slider = slider)} {...sliderSettings}>
                      {data.secondary_questions !== undefined &&
                        data.secondary_questions.map((question, index) => (
                          <SecondaryQuestion
                            key={question.id}
                            q_num={index + 1}
                            total_q={data.secondary_questions.length}
                            question={question}
                            direction={index == 0 ? `start` : teamData.direction}
                            hintEnabled={data.hunt.enable_hints}
                            showAnswersEnabled={data.hunt.enable_answers}
                            soundsEnabled={this.state.play_sounds}
                            success={this.playSuccess}
                            fail={this.playFail}
                            softFail={this.playSoftFail}
                            successText={this.state.end_text.success}
                            partialSuccessText={this.state.end_text.partial}
                            failText={this.state.end_text.fail}
                            nextFunc={this.nextSlide}
                          />
                        ))}
                      {(this.state.huntEnded || hunt_status == `ended`) && (
                        <div className={`px-4 mb-5`}>
                          <CardScore nextFunc={this.nextSlide} />
                        </div>
                      )}
                      {this.state.huntEnded || hunt_status == `ended` ? (
                        <div className={`px-4 mb-5`}>
                          <CardThanksForPlaying />
                        </div>
                      ) : (
                        <div className={`mb-5`}>
                          <FinishBlock afterFinish={this.afterFinish} />
                        </div>
                      )}
                    </Slider>
                    <div className="mx-5 px-3 position-relative d-md-none">
                      <Slider
                        className="dot-slider position-absolute"
                        ref={slider => (this.dotSlider = slider)}
                        {...dotSliderSettings}>
                        {data.secondary_questions !== undefined &&
                          data.secondary_questions.map((question, index) => (
                            <div key={`key-${index}`} className="dot-slide text-center mr-2">
                              <span className="dot"></span>
                            </div>
                          ))}
                        <div className="dot-slide text-center mr-2">
                          <span className="dot"></span>
                        </div>
                        {(this.state.huntEnded || hunt_status == `ended`) && (
                          <div className="dot-slide text-center mr-2">
                            <span className="dot"></span>
                          </div>
                        )}
                      </Slider>
                    </div>
                  </div>
                )}
              </div>
              <CompleteModal
                modalShow={this.state.modalShow}
                modalDismissed={this.state.modalDismissed}
                modalClose={this.modalClose}
              />
            </LoggedInWrapper>
          )
        }}
      </AppContext.Consumer>
    )
  }
}
